<template>
  <div>22</div>
</template>

<script>
export default {
  name: 'show',
};
</script>

<style scoped></style>
